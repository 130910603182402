section.icon-text-list {

    svg {
        width: 100%;
        height: auto;
    }

    .entry-text {
        ul {
            @include menu-base;
            @include menu-direction(vertical);
            text-align: center;

            li {
                margin-bottom: 0.8rem;
                position: relative;

                &:not(:last-child):after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -0.4rem;
                    width: 1rem;
                    left: calc(50% - 0.5rem);
                    border-top: 2px solid lighten($body-font-color, 50%);
                }
            }
        }

        @each $key,
        $color in $color-palette {
            &.#{$key} {
                ul {
                    li {
                        &:not(:last-child):after {
                            border-color: $color
                        }
                    }
                }
            }
        }
    }
}