section.projects {

    a {
        &.slick-trigger {
            // color: $green;

            svg {}

            &.expanded {
                // color: $green;

                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .more-info {
        // visibility: hidden;
        // position: absolute;
        display: none;

        &.expanded {
            // visibility: visible;
            // position: relative;
            display: flex;
        }
    }

    .close-toggler {
        display: none;

        svg,
        .text {
            display: inline-block;
            vertical-align: middle;
        }

        &.expanded {
            display: flex;
        }
    }

    .img {
        min-height: 400px;
        max-height: 640px;
        background-size: cover;
        background-position: center;
        background-color: $light-gray;
    }

    .cat {
        b {
            color: $green;
        }
    }

    a {
        &.icon-button {

            @each $key,
            $color in $color-palette {
                &.bg-#{$key} {
                    &:hover {
                        animation: gradient-scroll 300ms ease both !important;
                    }
                }
            }

            &.bg-white {
                transition: background-color 300ms ease-out;

                &:hover {
                    background-color: $light-gray;
                    transition: background-color 300ms ease-in;
                }
            }
        }
    }

    .icon-button {

        border-radius: 30px;
        outline: 0 !important;


        .icon {
            border-right: solid 1px #a4d56b;
        }

        .subtitle {
            text-transform: uppercase;
        }

        @each $key,
        $color in $color-palette {

            .icon.#{$key} {
                border-right: solid 1px $color;
            }

            &.bg-#{$key} {
                background-color: $white;
                background-image: linear-gradient(17deg, rgba($color, 0.9) 0%, rgba($color, 0.7) 100%);
                color: $white;
                background-color: transparent;
                transform: translateZ(0);
                background-size: 200% 200%;
                background-position: 100% 0%;
                will-change: background-position;
                animation: gradient-scroll-back 700ms ease both !important;

                .icon {
                    border-right: solid 1px white;
                }
            }


        }


        // &.bg-green {
        //     background: linear-gradient(49deg, #008a00 -84%, #dcf2be 188%);
        //     color: $white;
        //     background-color: transparent;
        //     transform: translateZ(0);
        //     background-size: 200% 200%;
        //     background-position: 100% 0%;
        //     will-change: background-position;
        //     animation: gradient-scroll-back 700ms ease both !important;
        // }
        &.bg-white {
            color: $green;
            background-color: $white;
        }

        svg {
            max-width: 100%;
            width: 80px;
            height: 80px;
        }
    }

    .white-bg {
        background-color: $white;
    }


    .slider-container {

        .slider {

            .slick-slide {
                -webkit-transition: opacity 0.5s ease-in-out;
                -moz-transition: opacity 0.5s ease-in-out;
                transition: opacity 0.5s ease-in-out;
                opacity: 0.5;
                outline: 0 !important;

                a {
                    outline: 0 !important;
                }
            }


            .slick-current {
                opacity: 1.0;
            }

            .slick-dots {
                margin-top: 1rem;
            }

        }
    }

    .slider {
        .slick-dots {
            position: relative;
            bottom: 0;
            line-height: 0;

            li {
                button {
                    &:before {
                        font-size: 40px;
                        opacity: 0.25 !important;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            opacity: 1.0 !important;
                        }
                    }
                }
            }
        }
    }

    @each $key,
    $color in $color-palette {
        .slider.#{$key} .slick-dots li button:before {
            color: $color !important;
        }
    }

    .project-archive {

        .cat {
            // color: $green;
            margin-bottom: 0;
        }

        .entry {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid $dark-gray;
        }

    }

    .icon-button {

        svg.icon-white {

            g.shade {

                path,
                polyline,
                polygon,
                line,
                rect,
                circle {
                    fill: rgba($white, 0.2) !important
                }
            }

            g.outline {

                path,
                polyline,
                line,
                rect,
                circle,
                polygon {
                    stroke: $white !important;
                    fill: none
                }
            }
        }
    }
}