.footer {

    background-color: lighten(get-color(secondary), 20%);

    .footer-subline{
        background-color: get-color(secondary)
    }

    .footer-subline{
        // border-top: 1px solid $medium-gray;

        .menu{
            li{
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }

}
