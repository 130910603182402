section.paragraph {
    .content {
        position: relative;

        .box {
            background: $white;
        }

        .menu {
            li {
                a {
                    padding-left: 0;
                }
            }
        }

        .link-list{
            margin-left: 0;
            a{
                text-transform: none;
            }
            li{
                margin-bottom: 1rem;
            }
        }

        .text-container {
            position: relative;

            h3.label {
                height: 6rem;
                width: 6rem;
                line-height: 6rem;
                border-radius: 50%;
                text-align: center;
                background-color: $black;
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -50%) rotate(-10deg);
                z-index: 5;
            }

            @include breakpoint(small only) {

                &.padding-left-middle,
                &.padding-right-middle {
                    padding-top: 2rem;
                }
            }

            .text {
                z-index: 10;
                position: relative;
            }

            @include breakpoint(medium) {

                blockquote {
                    // margin-left: 16.6666666667%;
                    // margin-right: -16.6666666667%;
                    margin-left: 8%;
                    margin-right: -8%;
                }
            }

            @include breakpoint(small) {

                blockquote {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }

            }

        }
    }

    .illustration-container {
        position: relative;

        .illustration {
            z-index: 2;
            position: relative;
            transform: translateX(-50%);

            svg {
                height: auto;
                width: 100%;
            }
        }

        &.right {
            left: 100%;

            @include breakpoint(small only) {
                .illustration {
                    transform: translateX(-25%);
                }
            }

        }

        &.left {
            right: 0;

            @include breakpoint(small only) {
                .illustration {
                    transform: translateX(-75%);
                }
            }

        }
    }
}
