header.header {
    z-index: 1;

    .header-conatiner {
        position: relative;

        .img-video-container {
            height: calc(100vh - 80px);
            max-height: 800px;

            .video-container,
            .header-image {
                overflow-y: hidden;
                overflow-x: hidden;
                height: 100%;
                z-index: 1;
                position: fixed;
                will-change: transform;

                .image {
                    width: 100%;
                    height: calc(100vh - 80px);
                    max-height: 800px;
                    background-size: cover;
                    background-position: center;
                }
            }


        }

        .logo-container {

            position: absolute;
            z-index: 3;

            bottom: 0;
            left: 0;
            width: 100%;

            .logo {
                svg {
                    width: 100%;
                    height: auto;
                }
            }

            &.center {
                bottom: 50%;
                left: 50%;

                .logo {
                    transform: translate(-50%, 50%);
                }
            }
        }
    }


    @include breakpoint(medium only) {
        .grid-container {}
    }

    @include breakpoint(large) {
        .header-conatiner {
            .img-video-container {
                height: calc(100vh - 80px);
            }
        }
    }
}