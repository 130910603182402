body {
    min-height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    // font-size: 1.3em;
    // background-image: linear-gradient(180deg, get-color(primary), get-color(secondary));

    .white {
        background-color: $white;
    }

    main {
        background-color: $white;
        min-height: 100vh;
        flex: 1;
    }

    &.gray {
        background-color: $light-gray;

        main {
            background-color: $light-gray;
        }
    }

    &.black {
        background-color: $black;

        main {
            background-color: $black;
        }
    }

    &.white {
        background-color: $white;

        main {
            background-color: $white;
        }
    }


    .paper-border {
        position: relative;

        &:before {
            position: absolute;
            width: 100%;
            content: '';
            bottom: 100%;
            height: 10px;
            left: 0;
            right: 0;
            z-index: 10;
            background-repeat: no-repeat;
            background-position: center top;
            background-image: url("../img/bg/paper-top-white.svg");
            background-size: cover;
        }

        &.yellow {
            &:before {
                background-image: url("../img/bg/paper-top-yellow.svg");
            }
        }

        &.light-yellow {
            &:before {
                background-image: url("../img/bg/paper-top-lightyellow.svg");
            }
        }
    }



    .off-canvas-content {
        min-height: 100%;
        max-width: 100vw;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        min-height: 100vh;
        flex-direction: column;

        &.has-transition-overlap {}

    }

    section,
    header {
        position: relative;
        z-index: 2;
        background-color: $body-background;

        &.gray {
            background-color: $light-gray;

            main {
                background-color: $light-gray;
            }
        }

        &.black {
            background-color: $black;

            main {
                background-color: $black;
            }
        }

        &.white {
            background-color: $white;

            main {
                background-color: $white;
            }
        }
    }

    .content-container {
        img {
            height: auto;
            width: auto;
        }

        table {
            tr {
                td {
                    padding: 0.5rem;
                }
            }
        }

        ul {
            list-style: none;

            li {
                position: relative;

                &::before {
                    position: absolute;
                    content: "•";
                    color: get-color(secondary);
                    display: inline-block;
                    width: 1rem;
                    top: -0.2rem;
                    font-size: 1.2rem;
                    left: -1rem;
                }
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    video {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .video-container {
        cursor: pointer;
    }

    .grid-container.no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    #topbar-container,
    footer {
        z-index: 1;
        background-color: $white;
    }


    // @include breakpoint(medium) {

    //     main {
    //         margin-top: rem-calc(96);
    //     }

    //     header {
    //         margin-top: rem-calc(-96);
    //     }
    // }
}