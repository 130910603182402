.topbar-container {
    position: relative;
    z-index: 100;
    height: 76px;

}

.main-nav {
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 100%;
    
    background-color: $white;

    a {
        padding-top: 0px;
        padding-bottom: 0px;
        color: black;
        text-transform: none;
        font-size: 20px;
        font-weight: normal;
        height: 100%;
        &:hover{
            transition: color 300ms ease;
            color: $anchor-color-hover
        }

        &.is-active{
            font-weight: 600;
        }

        svg{
            max-height: 100%;
            height: 34px;
            width: auto;
        }
    }

    &.is-stuck {
        position: fixed;
        z-index: 5;
        width: 100%;

        &.is-at-top {
            top: 0;
        }

        &.is-at-bottom {
            bottom: 0;
        }
    }

    &.is-anchored {
        position: relative;
        right: auto;
        left: auto;

        &.is-at-bottom {
            bottom: 0;
        }
    }

    .grid-container {
        padding: 1rem;

        .top-bar {
            padding: 0;
            @include top-bar-unstack;

            .top-bar-left,
            .top-bar-right {

                .menu {
                    height: 100%;

                    li {

                        a.nav-link {
                            line-height: rem-calc(80);
                            padding-top: 0;
                            padding-bottom: 0;
                            transition: color 500ms ease;
                        }
                    }
                }
            }

            .top-bar-title {
                text-align: center;
                margin: 0;


                a {
                    display: block;
                    width: 100%;
                    height: rem-calc(80);
                    background-color: $light-gray;
                    padding-top: rem-calc(10);
                    padding-bottom: rem-calc(10);

                    svg {
                        height: 100%;
                        width: auto;
                        opacity: 1;
                    }

                }
            }
        }
    }

    @include breakpoint(small only) {
        .grid-container {
            .top-bar {

                .top-bar-left,
                .top-bar-right {}

                .top-bar-title {
                    text-align: left;

                    a {
                        background-color: transparent;
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                        height: rem-calc(64);

                    }
                }
            }
        }
    }
}
