$blue: #012c9f;
$green: #008a00;
$red: rgb(199,41,28);
$pink: #e896a8;
$yellow: rgb(255,223,127);

$foundation-palette: (primary: $red,
secondary: $yellow,
success: $green,
warning: $yellow,
alert: $red);


// $light-gray: #A1A1A1;
$light-gray: #f4f4f4;
$medium-gray: #A1A1A1;
$dark-gray: #8a8a8a;
$black: #000000;
$white: #fefefe;


$color-palette: (blue: $blue,
green: $green,
red: $red,
yellow: $yellow);

@each $key,
$color in $color-palette {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    em,
    b,
    .menu a,
    span {
        &.#{$key} {
            color: $color;

            svg {
                path,
                polyline,
                polygon,
                line,
                rect,
                circle {
                    stroke: $color;
                }
            }
        }
    }

    .menu a.#{$key}:hover,
    a.#{$key}:hover {
        transition: color 300ms ease;
        color: scale-color($color, $lightness: -25%);

        svg {

            path,
            polyline,
            polygon,
            line,
            rect,
            circle {
                stroke: scale-color($color, $lightness: -25%);
            }
        }
    }

    svg.#{$key} {
        g.shade {

            path,
            polyline,
            polygon,
            line,
            rect,
            circle {
                fill: rgba($color, 0.2) !important
            }
        }

        g.outline {

            path,
            polyline,
            polygon,
            line,
            rect,
            circle {
                stroke: $color !important
            }
        }
    }


    svg.link-arrow.#{$key} {

        path,
        polyline,
        polygon,
        line,
        rect,
        circle {
            stroke: $color !important
        }

        &:hover {
            svg.link-arrow.#{$key} {

                path,
                polyline,
                polygon,
                line,
                rect,
                circle {
                    stroke: scale-color($color, $lightness: -25%) !important
                }
            }
        }
    }

    a.button,
    button {
        &.#{$key} {
            background-color: transparent !important;
            background: linear-gradient(52deg, scale-color($color, $lightness: -25%) 0%, scale-color($color, $lightness: 50%) 100%);
            color: $white;
            background-size: 200% 200%;
            transform: translateZ(0);
            background-position: 100% 0%;
            will-change: background-position;
            animation: gradient-scroll-back 700ms ease both;

            &:hover {
                animation: gradient-scroll 700ms ease both;
            }
        }
    }


    div.bg-gradient-#{$key} {
        background-color: $white;
        background-image: linear-gradient(17deg, rgba($color, 0.40) 10%, rgba($color, 0.10) 120%);
    }
}

button:hover svg,
a:hover svg {
    g.shade {

        path,
        polyline,
        line,
        rect,
        circle polyline {
            fill: rgba($blue, 0.2)
        }
    }

    g.outline {

        path,
        polyline,
        line,
        rect,
        circle {
            stroke: $blue
        }
    }
}