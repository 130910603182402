section.path {

    .full-height{
        height: 100% !important;
    }

    background-color: $light-gray;

    .number{
        background-color: $light-gray;
        height: 40px;
    }

    .last-item{
        background-color: $light-gray;
        transform: translate(0%, 0%);
    }

    .icon{
        height: 130px;
        svg{
            width: 100%;
            height: auto;
            // .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7{
            //     stroke: $blue;
            // }
            // .shade{
            //     .cls-1, .cls-2, .cls-3, cls-4, .cls-5, .cls-6, .cls-7{
            //         fill: #b3deff;
            //         stroke: #b3deff;
            //     }
            // }
        }
    }

    .text{
        height: 130px;
    }

    .path-wrapper{
        position: relative;
    }

    .path-row{
        height: 300px;
    }

    .path{
        height: 306px;
        position: absolute;
        top: 0;
        z-index: -1;

        &.straight-left{
            border-top: 6px solid #5c8bd5;
            transform: translate(1%, -50%);
        }

        &.straight-right{
            border-top: 6px solid #5c8bd5;
            transform: translate(100%, -50%);
        }

        &.right-curve{
            border-top: 6px solid #5c8bd5;
            border-bottom: 6px solid #5c8bd5;
            border-right: 6px solid #5c8bd5;
            border-radius: 0px 100px 100px 0px;
            transform: translate(100%, -50%);
        }

        &.left-curve{
            border-top: 6px solid #5c8bd5;
            border-bottom: 6px solid #5c8bd5;
            border-left: 6px solid #5c8bd5;
            border-radius: 100px 0px 0px 100px;
            transform: translate(1%, -50%);
        }
    }

    .small-view{
        .svg{
            svg{
                width: 100%;
                height: auto;
            }
        }
    }

}
