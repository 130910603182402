section.img-text {

    @include breakpoint(small only) {
        &.left, &.right, &.middle{
            padding-top: 3.5rem;
        }
    }

    @include breakpoint(medium) {
        &.left, &.right{
            padding-top: 6rem;
        }
        &.middle{
            padding-bottom: 6rem;
        }
    }

	.grid-container {
		.box{
		    position: relative;

		    .box-image, box-image-mobile{
                position: relative;
		    }

			.illustration {

			    position: absolute;

                @include breakpoint(small only) {
                    svg {
                        height: 7rem;
                        width: auto;
                    }
                }

                @include breakpoint(medium) {
                    svg {
                        height: 10rem;
                        width: auto;
                    }
                }

				&.right{
				    top: 0px;
				    right: 0px;
				    transform: translate(50%, -50%);
				}
				&.left{
				    top: 0px;
				    transform: translate(-50%, -50%);
				}
				&.middle-left{
				    bottom: 0px;
				    right: 0px;
				    transform: translate(50%, 50%);
				}
                &.middle-right{
				    bottom: 0px;
				    left: 0px;
				    transform: translate(-50%, 50%);
				}
			}
		}
		.grid-x {
			.box-image {
				min-height: 400px;
				background-size: cover;
				background-position: center;
			}
            .box-image-mobile {
				height: 200px;
				background-size: cover;
				background-position: center;
			}
			.link {
				svg {
					.cls-1, .cls-2{
						fill: none !important;
					}
					polyline{
						stroke: $red;
					}
					polyline:hover{
						stroke: #bf2417;
					}
				}
			}
		}
	}
}
