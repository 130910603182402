@font-face {
    font-family: 'SourceSansPro';
    src: url('../font/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../font/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


span,
a {
    line-height: 1.5;
}

address {
    font-style: normal !important;
    line-height: $paragraph-lineheight;
    margin-bottom: $paragraph-margin-bottom;
}

cite {
    color: $anchor-color;
    font-style: normal !important;
    font-family: $body-font-family;
}

a,
.link {
    transition: color 300ms ease;
    text-transform: uppercase;
    // font-size: rem-calc(20px);
    line-height: 1.2;
    font-weight: bold;
    color: $anchor-color;

    svg.link-arrow {
        transform: translateX(0px) translateY(2px);
        transition-duration: 300ms;
        height: rem-calc(18px);
        margin-right: 8px;
        height: 1rem;
        width: auto;

        path,
        rect,
        line,
        polyline {
            stroke: $anchor-color;
        }
    }


    &:hover {
        transition: color 300ms ease;
        color: $anchor-color-hover;

        svg.link-arrow {
            transform: translateX(2px) translateY(2px);


            path,
            polyline {
                stroke: $anchor-color-hover;
            }
        }

        svg.link-arrow.down {
            transform: translateX(0px) translateY(4px) rotate(0);

            path,
            rect,
            line,
            polyline {
                stroke: $anchor-color-hover;
            }
        }

        svg.hover {

            path,
            rect,
            line,
            polyline {
                fill: $anchor-color-hover;
            }
        }
    }
}

.menu {

    a,
    .link {
        transition: color 300ms ease;
        color: $body-font-color;
        text-transform: none;
        font-weight: normal;

        &:hover {
            color: $anchor-color-hover;
        }

        &.is-active {
            font-weight: bold;
            color: scale-color($anchor-color, $lightness: -25%);
        }
    }
}

.fake-link {
    &:hover {
        cursor: pointer;
    }
}

.text-light {
    color: lighten($body-font-color, 30%);
}

p,
h1,
h2,
h3,
h4 {
    a {
        text-transform: none;
    }

    p {
        line-height: inherit;
    }
}

.subline {
    font-size: rem-calc(24);
    line-height: 1.33;
    letter-spacing: 1.5px;
    color: get-color(primary);
    font-family: $body-font-family !important;
    text-transform: uppercase;
}

.sans {
    font-family: $body-font-family !important;
}

h1,
.h1 {
    line-height: 1.44;
    letter-spacing: normal;
}

h2,
.h2 {
    line-height: 1.42;
    letter-spacing: normal;
}

h4,
.h4 {
    font-weight: bold;
}


blockquote {
    p {
        font-family: $header-font-family;
    }
}


@include breakpoint(small only) {
    .grid-container.small-full {
        padding-left: 0;
        padding-right: 0;
    }

    a,
    .link {
        font-size: rem-calc(18);
        letter-spacing: 1px;
    }

    h1,
    h2,
    h3,
    h4 {
        line-height: 1.33;
    }

}

@include breakpoint(medium) {

    h1,
    .h1 {
        line-height: 1.4;
        letter-spacing: normal;
    }

    h2,
    .h2 {
        line-height: 1.38;
        letter-spacing: normal;
    }


    blockquote {
        p {
            font-size: rem-calc(21);
        }
    }

}