.button,
.menu a.button {
    cursor: pointer;
    border: none;
    background-repeat: no-repeat;
    padding: 0.5rem 2rem;
    border-radius: 1.6rem;
    line-height: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.6px;
    font-size: 0.8rem;
    text-transform: uppercase;

    &:hover {
        color: $white;
    }
}