.off-canvas {
    background-color: $white;

    .grid-container {
        min-height: 100vh;
        
        a {
            @include normal-anchor();

            &:focus {
                outline: none;
            }
        }

        .top-bar {
            @include top-bar-unstack;

            .top-bar-left,
            .top-bar-right {
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;

                .logo {
                    width: auto;
                    height: rem-calc(65);
                }
            }
        }

        .menu-text {
            li {
                a {
                    padding-left: 0 !important;
                }
            }
        }

        .footer-accordion {
            a.accordion-title {
                h2 {
                    margin-top: 0;
                }
            }
        }
    }
}