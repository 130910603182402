section.table {
    background-image: linear-gradient(66deg, #b3deff 44%, #e4f3ff 149%);

    .item {
        border-top: 1px solid $dark-gray;

        .title {
            text-transform: uppercase;
            color: $blue;
        }

    }

}
